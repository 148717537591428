import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { FieldTypeDto } from '../data';
import jwt_decode from 'jwt-decode';
import { DefaultValuesService } from './default-values.service';

/*
  Generated class for the AccountServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

export enum ViewModes {
  RAISE = 'Raise',
  POPULATE = 'Populate',
  NO_ROLE = 'NoRole',
  ONLY_FORMS = 'OnlyForms',
  ONLY_RAISE = 'OnlyRaise',
}

export interface DecodedToken {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string[];
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  'allowed-origins': string[];
  realm_access: RealmAccess;
  resource_access: ResourceAccess;
  scope: string;
  sid: string;
  email_verified: boolean;
  name: string;
  teamPaths: string[];
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
}

export interface RealmAccess {
  roles: string[];
}

export interface ResourceAccess {
  'user-managed-access': RealmAccess;
  'dashboard-ms': RealmAccess;
  account: RealmAccess;
}

const DATCH_TEAM_PATH = '/demo/demo';
const TECONNEX_UK_TEAM_PATH = '/teconnex/pilot';
const TECONNEX_ROMANIA_TEAM_PATH = '/teconnex/romania';
const TECONNEX_INDIA_TEAM_PATH = '/teconnex-wzcocjz/default';
const TECONNEX_MEXICO_TEAM_PATH = '/teconnex-3uifyi3/default';

/** Only these teampaths are allowed to view and create custom dashboards
 */
const dashboardTeamPaths = new Set([
  DATCH_TEAM_PATH,
  TECONNEX_UK_TEAM_PATH,
  TECONNEX_ROMANIA_TEAM_PATH,
  'teconnex-dak6bi7/default', // Unknown team path.
  TECONNEX_INDIA_TEAM_PATH,
  TECONNEX_MEXICO_TEAM_PATH,
]);

@Injectable()
export class ViewModeService {
  constructor(public keycloakAngular: KeycloakService) {}

  async getViewMode(): Promise<ViewModes> {
    if (await this.keycloakAngular.isLoggedIn()) {
      const tok = jwt_decode<DecodedToken>(await this.keycloakAngular.getToken());
      if (!this.shouldShowDashboardMenu(tok)) {
        return ViewModes.ONLY_FORMS;
      }

      if (this.keycloakAngular.isUserInRole('Populate')) return ViewModes.POPULATE;
      if (this.keycloakAngular.isUserInRole('Quickstart')) return ViewModes.ONLY_RAISE;
      if (this.keycloakAngular.isUserInRole('Raise')) return ViewModes.RAISE;
      else return ViewModes.NO_ROLE;
    } else return Promise.resolve(null);
  }

  private shouldShowDashboardMenu(decodedToken: DecodedToken) {
    return (decodedToken['teamPaths'] || []).some((path: string) => dashboardTeamPaths.has(path));
  }

  isRestricted(formField: FieldTypeDto, view: ViewModes) {
    if (view === ViewModes.POPULATE) {
      return false;
    } else if (
      (view === ViewModes.RAISE || view === ViewModes.ONLY_RAISE) &&
      (formField.key === 'raisedBy' || formField.key === 'location')
    ) {
      return false;
    } else if ((view === ViewModes.RAISE || view === ViewModes.ONLY_RAISE) && formField.required !== 'INIT') {
      return true;
    } else if (
      (view === ViewModes.RAISE || view === ViewModes.ONLY_RAISE) &&
      formField.controlType === 'status' &&
      DefaultValuesService.hasDefaultOptions(formField)
    ) {
      return true;
    } else if (
      (view === ViewModes.RAISE || view === ViewModes.ONLY_RAISE) &&
      DefaultValuesService.hasDefaultOptions(formField)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
